import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import SEO from "../../components/Seo";
import Layout from "../../components/Layout";
import MainContent from "../../components/MainContent";
import Breadcrumb from "../../components/Breadcrumb";
import Img from "../../components/Img.js";
import SocialWrapper from "../../components/SocialWrapper";
import H from "../../components/Headline";
import { useAlternateLangs } from "../../components/Hreflangs.js";
import Banner from "../../components/Banner";
import EmailSignupSimplePlugin from "../../components/EmailSignupSimplePlugin";
import { experienceYears } from "../../components/Helpers";

const StyledMiddle = styled.div`
  margin: 0vmin 1vmin;
  .container {
    margin: 7vmin 0vmin;
    display: flex;
  }

  h1 {
    padding: 0;
    margin: 0rem 0rem 1rem 0rem;
  }

  .content {
    width: 60%;
    margin-right: 1rem;
  }

  .image-wrapper {
    margin: 0rem 0rem auto 0rem;
    width: 30%;

    & .gatsby-image-wrapper {
      box-shadow: 0px 0px 3px var(--foreground);
      margin-bottom: 1rem;
    }
  }
  @media all and (max-width: 540px) {
    justify-content: center;
    .container {
      flex-direction: column-reverse;
    }

    .content {
      width: 100%;
      margin-right: none;
    }

    .image-wrapper {
      margin: 1rem auto 4rem auto;
      width: 60%;
    }
  }
`;

const StyledSecondaryContent = styled.div`
  grid-area: secondary;
  background: orange;
  display: flex;

  .card {
    border-top: 1px solid var(--foreground);
    &:first-child {
      border-right: 1px solid var(--foreground);
    }
  }

  @media all and (max-width: 700px) {
    flex-direction: column;
  }
`;

// Breadcrumb data

const breadCrumbLevels = {
    Accueil: "/fr",
    "À propos de moi": "/fr/à-propos"
  };
  
// Hreflang data

const alternateLangs = useAlternateLangs(
  // replace with pages x-default
  "/en/about"
);

  const About = props => (
    <Layout location={props.location} alternateLangs={alternateLangs}>
  
      <React.Fragment>
        <SEO title="À propos de Matthias Kupperschmidt" lang="fr" alternateLangs={alternateLangs} />
        <MainContent maxWidth="700">
          <StyledMiddle>
            <Breadcrumb breadCrumbLevels={breadCrumbLevels} className="breadcrumb" />
            <div className="container">
              <div className="content">
                <H as="h1">Bonjour, je suis Matthias</H>
                <p>J'optimise les sites web pour les résultats de recherche Google et je mets en place des configurations analytiques.</p>
              <p>
                Je suis allemand, vivant à Copenhague, au Danemark. J'ai travaillé pendant {experienceYears} ans dans plusieurs agences digitales
                avant de commencer à travailler en tant que <Link to="/fr/google-analytics-freelance">freelance pour Google Analytics</Link> et SEO.
              </p>
              <p>
                Selon plusieurs tests de personnalité, je suis très analytique, rationnel et structuré, tout en étant
                orienté vers les objectifs et pragmatique en même temps.
              </p>
              <H as="h2">Mon histoire</H>
              <p>
                À 14 ans, j'ai créé mon premier site web pour échanger des films dans la cour de l'école. C'était le meilleur moyen de montrer ma
                collection de films à mes camarades de classe. Je me souviens d'avoir créé les premiers HTML avec Microsoft Word et plus tard
                avec un éditeur appelé "Phase5".
              </p>
              <p>
                Plus tard, j'ai créé ma propre boutique en ligne de baby-foot (ou “foosball” comme nous l'appelons), appelée
                "Kickerkult". J'avais 18 ans et j'étais un joueur de baby-foot amateur aspirant à devenir professionnel, donc c'était une bonne
                combinaison de deux intérêts que j'avais.
                <br /> Pendant cette période, j'ai joué à de nombreux tournois à Berlin et à travers l'Allemagne. Pendant ce temps, je gérais
                ma boutique en ligne, apprenais à envoyer des newsletters par email et commençais à faire du SEO.
                <br />
                Finalement, je suis devenu quelque peu accro au web et à ses possibilités et j'ai voulu orienter ma carrière dans ce domaine.
              </p>
              <p>
                Aujourd'hui, j'aide des entreprises de différentes tailles à atteindre le sommet des classements de recherche Google et à
                suivre le comportement de leurs utilisateurs - peu importe d'où vient l'entreprise dans le monde.
              </p>
              <p>
                Eh bien, de plus, j'écris sur des sujets SEO et Analytics sur mon <Link to="/fr/blog">blog</Link>.
              </p>
              <H as="h2">
                Quelle ville est la prochaine Silicon Valley ?
                <br />
                La vôtre !
              </H>
              <p>
                Je suis fermement convaincu qu'il ne faut pas déménager dans les grandes villes glorieuses pour réussir une grande carrière.
                Grâce à Internet, les entreprises modernes, les professionnels et les personnes partageant les mêmes idées se trouvent et travaillent
                ensemble - quelle que soit la distance géographique.
              </p>
            </div>
            <div className="image-wrapper">
              <Img clickable="false" src="about/matthias-kupperschmidt-about-me-portrait.jpeg" alt="matthias kupperschmidt" />
              <SocialWrapper align="center" twitter="mattzipan" linkedin="matthiaskupperschmidt" github="mattzipan" />
            </div>
          </div>
        </StyledMiddle>
      </MainContent>
      <StyledSecondaryContent>
        <Banner>
          <div className="banner__header serif headline">Restons en contact !</div>
          <div className="serif">
            Ne vous inquiétez pas, je vous enverrai à peine des emails. Inscrivez-vous ici pour être sûr de recevoir mes informations importantes.
          </div>
          <div className="emailwrapper">
            <EmailSignupSimplePlugin />
          </div>
        </Banner>
        <Banner>
          {/* <div className="banner__header serif headline">
                “J'ai besoin d'aide pour le SEO ou l'analyse”
              </div>
              <div className="serif">
                Ne vous inquiétez pas, je vous enverrai à peine des emails. Inscrivez-vous ici pour être sûr de
                recevoir mes informations importantes.
              </div> */}
        </Banner>
      </StyledSecondaryContent>
    </React.Fragment>

  </Layout>
);

export default React.memo(About);
